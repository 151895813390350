/* Generator: https://wweb.dev/resources/animated-css-background-generator/ */

@keyframes animate {
    0%{
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }
    100%{
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }
}

.background {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    overflow: hidden;
    pointer-events: none;
}
.background li {
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0.2);
    animation: animate 19s linear infinite;
}




.background li:nth-child(0) {
    left: 14%;
    width: 152px;
    height: 152px;
    bottom: -152px;
    animation-delay: 1s;
}
.background li:nth-child(1) {
    left: 2%;
    width: 102px;
    height: 102px;
    bottom: -102px;
    animation-delay: 5s;
}
.background li:nth-child(2) {
    left: 14%;
    width: 126px;
    height: 126px;
    bottom: -126px;
    animation-delay: 4s;
}
.background li:nth-child(3) {
    left: 32%;
    width: 113px;
    height: 113px;
    bottom: -113px;
    animation-delay: 10s;
}
.background li:nth-child(4) {
    left: 51%;
    width: 118px;
    height: 118px;
    bottom: -118px;
    animation-delay: 4s;
}
.background li:nth-child(5) {
    left: 9%;
    width: 109px;
    height: 109px;
    bottom: -109px;
    animation-delay: 8s;
}
.background li:nth-child(6) {
    left: 71%;
    width: 167px;
    height: 167px;
    bottom: -167px;
    animation-delay: 14s;
}
.background li:nth-child(7) {
    left: 21%;
    width: 155px;
    height: 155px;
    bottom: -155px;
    animation-delay: 19s;
}
.background li:nth-child(8) {
    left: 72%;
    width: 165px;
    height: 165px;
    bottom: -165px;
    animation-delay: 3s;
}
.background li:nth-child(9) {
    left: 47%;
    width: 102px;
    height: 102px;
    bottom: -102px;
    animation-delay: 12s;
}