.Header {
    min-height: 100vh;
    margin: 0;
    padding: 0;
    color: white;
    background-color: #2C1338;
    background: linear-gradient(-45deg, black, #181338, #381313, black);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}


.Header-main {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding: 60px 0px;
    box-sizing: border-box;
}

.Header-main-phrase {
    margin: 0;
    font-size: 48pt;
    width: 100%;
}

@media only screen and (max-width: 600px) {
    .Header-main-phrase {
        font-size: 36pt;
    }
}

@media only screen and (max-width: 350px) {
    .Header-main-phrase {
        font-size: 18pt;
    }
}


.Header-main-text {
    max-width: 500px;
    margin: 40px;
    width: 100%;
}

.Header-main-image {
    max-width: 600px;
    width: 90%;
}