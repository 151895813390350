.Menu {
    padding: 10px;
    margin: 0;
    color: white;
    text-align: left;
    position: fixed;
    width: 100%;
    top: 0px;
}

.Menu-title {
    margin: 10px;
    padding: 0;
}


@media only screen and (max-height: 600px) {
    .Menu {
        position: absolute;
    }
}